<template>
  <div class="not-found-container">
    <h1 class="not-found-title">404</h1>
    <h2 class="not-found-subtitle">الصفحة غير موجودة</h2>
    <p class="not-found-message">عذراً، الصفحة التي تبحث عنها غير متوفرة</p>
    <q-btn
      color="primary"
      label="عودة للبحث"
      class="not-found-button"
      @click="goToHome"
      aria-label="goToHome"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'NotFoundPage',
  setup() {
    const router = useRouter();

    const goToHome = () => {
      router.push('/');
    };

    return {
      goToHome,
    };
  },
});
</script>
